import { Filters, OptionSelect, OptionSelectOperation, PaginateRequest, PaginateResponse } from '@/core/model';
import { Operation } from '@/core/model/Operation';
import { getPaginate, getUsersByRoom } from '@/core/service/Operation';
import { reactive } from 'vue';

const state = reactive<{ operations?: Operation[];  listUsersOperationsSelect: OptionSelect[] }>({
  operations: undefined,
  listUsersOperationsSelect: [],
});


const actions = {
  async GetAll(forceRefresh = false, paginate?: PaginateRequest, filters?: Filters): Promise<PaginateResponse<Operation> | any> {
    if (!state.operations || forceRefresh) {
      const { data, page, total, totalPages, limit } = await getPaginate(paginate, filters);
      state.operations = data;

      return {
        data: data, page, totalPages, limit, total
      }
    }
  },

  async GetPaginate(paginate: PaginateRequest, filters?: Filters): Promise<PaginateResponse<Operation>> {
    return await this.GetAll(paginate.force, paginate, filters);
  },

  async GetUsersByRoom(location: string): Promise<void> {
    const users = await getUsersByRoom(location);
    state.listUsersOperationsSelect = users?.map((u) => ({
      text: u.name,
      value: u.doc,
    }))
  }
};

const getters = {};

export { state, actions, getters };
